import { graphql, Link, PageProps } from "gatsby";
import React from "react";
import { Footer } from "../components/Footer";
import { Nav } from "../components/Nav";
import { Pagination } from "../components/pagination";
import { PostCard } from "../components/PostCard";
import { SEO } from "../components/SEO";

interface PropType {
	metadata: Queries.WpSiteMetadataConnection
	category: Queries.WpCategory
	tags: Queries.WpTagConnection
}
export default (props: PageProps<PropType, { maxPage: number, currentPage: number }>) => {
	console.log(props);
	return (
		<>
			<Nav navGroup={props.data.metadata.nodes[0].page_meta!.navLink!} />
			<section className="container py-5">
				<div className="text-center mb-5">
					<h2>All Blog posts by tags</h2>
				</div>
			</section>
			{props.data.tags.nodes.map(tag => (
				<section className="container pb-5">
					<h3>Posts tagged with {tag.name}</h3>
					<div className="row">
						{tag.posts?.nodes?.slice(0, 3).map(post => <PostCard post={post!} />)}
					</div>
					<div className="d-flex justify-content-end mt-3">
						<Link to={`/blog/tags/${tag.slug}`} className="btn btn-outline-primary">Read More</Link>
					</div>
				</section>
			))}
			<section>
				<Pagination
					maxPages={props.pageContext.maxPage}
					currentPage={props.pageContext.currentPage}
					pageUrlGen={(ndx) => `/blog/tags/${ndx}`}
				/>
			</section>
			<Footer navGroup={props.data.metadata.nodes[0].page_meta?.footer?.nav_links2!} />
		</>
	);
}

export const Head = (props: PageProps<PropType>) => {
	return <SEO title="Misty Blog | Tags" description="Read all the latest" pathname="/blog/tags" />
}

export const pageQuery = graphql`
query ($tagIDs: [Int]){
	tags: allWpTag(filter: { databaseId: {in: $tagIDs}}) {
		nodes {
			slug
			databaseId
			name
			description
			posts {
				...PostCardTagFragment
			}
		}
	}
	metadata: allWpSiteMetadata(sort: {fields: modified}) {
    nodes {
      page_meta {
				footer {
					...LinkGroup
				}
				navLink {
					...LinkGroup
				}
      }
		}
	}
}`